import React, { useEffect, useState } from 'react';
import MainLayout from '../layouts/mainLayout';
// import { useParams } from 'react-router-dom';
import { useLocation } from "@reach/router";
import { useAppDispatch } from '../state/hooks';
import { fetchPaymentDetailFormSessionId } from '../state/basketSlice';
import CustomSpinner from 'components/Spinner';
import ButtonLoader from '../components/common/ButtonLoader';
import { navigate } from 'gatsby';


export default ({}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  // Extract the query parameters from the location
  const searchParams = new URLSearchParams(location.search);
  // Get the sessionId from the query parameters, if any
  // const sessionId = searchParams.get("sessionId") || null;
  const orderId = searchParams.get("order_id");

  // Get the transactionId from the query parameters, if any
  // const transactionId = searchParams.get("transactionId") || null;
  const orderType = searchParams.get("ot") || null;
  // const basketId = searchParams.get("basket");
  // const [data, setData] = useState({});
  // const [orderId, setorderId] = useState('');
  // const [pageLoading, setPageLoading] = useState(false);

  // useEffect(() => {
  //   const addressData = localStorage.getItem('addressData');
  //   let newAddressData = {}
  //   if (addressData) {
  //     newAddressData = JSON.parse(addressData);
  //   }
  //   newAddressData = { ...newAddressData, sessionId: sessionId, transactionId: transactionId};
  //   setPageLoading(true);
  //   dispatch(fetchPaymentDetailFormSessionId(newAddressData)).then((paymentData: any) => {
  //     setorderId(paymentData.payload.data.order)
  //     setPageLoading(false);
  //   });
  // }, [])
  return (
    <>
      <MainLayout>
        <div className="mgn-top-100 text-center mgn-bot-100">
          <div className="container-fluid">
            {/* {pageLoading ? (
              <CustomSpinner
                children
                disableBodyScroll={true}
                blurValue={5}
                isLoading={pageLoading}
                extras={'ifScroll'}
                isSpinner={true}
                texts={[
                  'Placing Order.',
                  'Placing Order..',
                  'Placing Order...',
                  'Placing Order....',
                ]}
              />           
            ) : ( */}
              <div className="def-w-max">
                <h2 className="text-center">
                  Payment Successful
                  <br />
                  <div className="text-center">
                    <ButtonLoader
                      className="btn-def mgn-top-30"
                      text="Go to Order Summary"
                      onClick={() => {
                          navigate(`/ordersuccess/?ot=${orderType}&orderId=${orderId}`);

                      }}
                      loading={false}
                      disabled={false}
                    />
                    </div>
                </h2>
              </div>
             {/* )} */}
          </div>
        </div>
      </MainLayout>
    </>
  );
};